.input {
  width: 100%;
  margin-bottom: 2rem;

  color: var(--color-content-color);

  border: solid 1px var(--color-content-color);
  border-radius: var(--radius-base);

  padding: 0.75rem 1rem;
}

.input:disabled {
  background-color: var(--color-grey-100);
  border-color: var(--color-disabled);
  opacity: 70%;
}

.invalid {
  color: var(--color-warning);
  border-color: var(--color-warning);
}

.range {
  width: 100%;
  background-color: transparent;
}
.range::-moz-range-track {
  width: 100%;
  height: 6px;
  background-color: var(--color-grey-700);
  border: 1px solid var(--color-grey-700);
  border-radius: 50px;
}
.range::-moz-range-thumb {
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--color-primary);
  cursor: pointer;
}
.range::-moz-range-progress{
  background-color: var(--color-primary);
  border-radius: 50px 0 0 50px;
  height: 8px;
}
.range::-ms-fill-lower {
  background: var(--color-primary);
  border-radius: 50px 0 0 50px;
}
.range::-ms-track {
  width: 100%;
  height: 6px;
  background-color: var(--color-grey-700);
  border: 1px solid var(--color-grey-700);
  border-radius: 50px;
}
.range::-ms-thumb {
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: var(--color-primary);
  cursor: pointer;
}

