.checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 2rem;
  background-color: var(--color-grey-700);
  border-radius: 9999px;
  border: 1px solid var(--color-grey-700);
  cursor: pointer;
}

.toggle::after {
  content: '';
  position: absolute;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 9999px;
  background-color: var(--color-white);
  top: 1px;
  left: 1px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.checkbox:checked + .toggle {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
}

.checkbox:checked + .toggle::after {
  left: 1.25rem;
}

.checkbox:focus + .toggle {
  outline: auto;
}

.checkbox:disabled + .toggle {
  opacity: 0.7;
  filter: grayscale(40%);
  cursor: not-allowed;
}
